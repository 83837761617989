import React from "react";
import { LocalOffer, Settings } from "@mui/icons-material";
import { Button, Typography } from "@mui/material";
import { ScissorsCutting } from "mdi-material-ui";
import { AuthenticationGuard } from "~/domain/auth";
import {
  CompleteProfileDialog,
  ProfileCompletionGuard,
} from "~/domain/profile";
import { SidebarHeader } from "~/layout";
import {
  DigestionSidebar,
  DigestionStateProvider,
  PanelControlsSidebar,
  PlayerPage,
  PlayerSettingsSidebar,
  TaggingSidebar,
} from "~/pages/player";
import type { TopicInitializationOverrider } from "~/player";
import {
  PlaybackSpeed,
  ThreeDInteractivityIndicator,
  Timestep,
  VisualizationType,
} from "~/player";
import { useAuthDialogControls } from "../auth-dialog";
import { PublicPlayerHeaderTitle } from "./header-title";

export function PublicPlayerPage() {
  return (
    <PlayerPage
      dense
      defaultPlaybackSpeed={PlaybackSpeed.TimesOne}
      threeDInteractivityIndicator={<ThreeDInteractivityIndicator />}
      disableSettingDefaultLayout
      headerTitle={<PublicPlayerHeaderTitle />}
      renderSidebarWrapper={(children) => (
        <DigestionStateProvider naming="extraction">
          {children}
        </DigestionStateProvider>
      )}
      visualizationStoreParams={{
        [VisualizationType.Timeline]: {
          timestepOverride: Timestep.Decisecond,
          count: 34 * 10,
          limit: 100,
          prefetchBehind: 30 * 10,
          prefetchAhead: 30 * 10,
        },
        [VisualizationType.Chart]: {
          timestepOverride: Timestep.Second,
          count: 34,
          limit: 30,
          prefetchBehind: 30,
          prefetchAhead: 30,
        },
        [VisualizationType.Image]: {
          count: 20,
          limit: 20,
          prefetchBehind: 20,
          prefetchAhead: 80,
        },
        [VisualizationType.ThreeD]: {
          count: 20,
          limit: 5,
          prefetchBehind: 10,
          prefetchAhead: 20,
        },
      }}
      sidebars={[
        {
          sidebarId: "tagging",
          trigger: {
            title: "Tagging",
            icon: <LocalOffer />,
          },
          element: <TaggingSidebar />,
        },
        {
          sidebarId: "digestions",
          trigger: {
            title: "Extractions",
            icon: <ScissorsCutting />,
          },
          element: <PublicDigestionsSidebar />,
        },
        {
          sidebarId: "settings",
          trigger: {
            title: "Settings",
            icon: <Settings />,
          },
          element: <PlayerSettingsSidebar />,
        },
        {
          sidebarId: "panel-controls",
          element: <PanelControlsSidebar />,
        },
      ]}
      overridePanelInitializationState={overridePanelInitializationState}
    />
  );
}

function PublicDigestionsSidebar() {
  const authDialogControls = useAuthDialogControls();

  return (
    <AuthenticationGuard
      unauthenticatedFallback={
        <>
          <SidebarHeader title="Create an Extraction" />
          <Typography gutterBottom>
            You must be signed in to create an extraction.
          </Typography>
          <Button
            color="primary"
            variant="contained"
            fullWidth
            onClick={authDialogControls.open}
          >
            Sign In / Sign Up
          </Button>
        </>
      }
    >
      <ProfileCompletionGuard
        pending={<DigestionSidebar />}
        error={<DigestionSidebar />}
        incomplete={
          <>
            <SidebarHeader title="Create an Extraction" />
            <CompleteProfileDialog />
          </>
        }
      >
        <DigestionSidebar />
      </ProfileCompletionGuard>
    </AuthenticationGuard>
  );
}

const overridePanelInitializationState: TopicInitializationOverrider = (
  defaultVisualization,
  topicName,
) => {
  if (defaultVisualization !== VisualizationType.Image) {
    return;
  }

  if (topicName.includes("disparity")) {
    return {
      colorize: true,
    };
  }
};
